import Cover from "../pdf_components/Cover";
import WorkDetails from "../pdf_components/WorkDetails";
import FinanceSummary from "../pdf_components/FinanceSummary";
import Doors from "../pdf_components/Doors";
import Electric from "../pdf_components/Electric";
import AttacheFinancial from "../pdf_components/AttacheFinancial";
import Aluminum from "../pdf_components/Aluminum";
import SkeletonWorks from "../pdf_components/SkeletonWorks";

export const chapters = {
  שער: {
    component: Cover,
    noSections: true,
  },
  "תיאור עבודה": {
    img: "/images/work_desc.jpg",
    icon: "/icons/icon7.svg",
    tableClass: "table2",
    component: WorkDetails,
  },
  "ארגון האתר והכנות": {
    icon: "/icons/icon8.svg",
    notFullPage: true,
    group: 3,
  },
  "": {
    icon: "/icons/icon9.svg",
    notFullPage: true,
    group: 3,
  },
  "גגות רעפים": {
    img: "/images/roofs.jpg",
    icon: "/icons/icon12.svg",
  },
  "עבודות בניה ושלד": {
    img: "/images/skeleton.jpg",
    icon: "/icons/icon11.svg",
    tableClass: "table2",
    component: SkeletonWorks,
  },
  "עבודות גבס": {
    img: "/images/drywall.jpg",
    icon: "/icons/plaster.svg",
    tableClass: "table4",
  },
  "איטום ובידוד": {
    img: "/images/sealing.jpg",
    icon: "/icons/icon14.svg",
    breakSections: true,
  },
  "חשמל ותקשורת ": {
    icon: "/icons/icon16.svg",
    img: "/images/electricity.jpg",
  },
  אינסטלציה: {
    img: "/images/images11.jpg",
    icon: "/icons/icon17.svg",
    tableClass: "table2",
  },
  "ריצוף וחיפוי": {
    icon: "/icons/flooring.svg",
    tableClass: "table4",
    img: "/images/flooring.jpg",
    notFullPage: true,
    group: 4,
    priority: 1,
  },
  "ריצוף וחיפוי חדרים רטובים": {
    icon: "/icons/wetRooms.svg",
    img: "/images/image_flooring_13.jpg",
    tableClass: "table4",
    noSections: true,
    group: 4,
  },
  "עבודות פיתוח": {
    img: "/images/images18.jpg",
    icon: "/icons/icon25.svg",
  },
  "גדרות וקירות תמך": {
    icon: "/icons/fences.svg",
    notFullPage: true,
    group: 2,
  },
  "נגרות ומסגרות חוץ": {
    icon: "/icons/metal.svg",
    notFullPage: true,
    group: 2,
  },
  "פיתוח נוף": {
    icon: "/icons/gardening.svg",
    notFullPage: true,
    group: 2,
  },
  בריכה: {
    img: "/images/pool.jpg",
    icon: "/icons/gardening.svg",
    notFullPage: true,
    group: 2,
  },
  אלומיניום: {
    img: "/images/image_alumunium_15.jpg",
    icon: "/icons/icon24.svg",
    tableClass: "table4",
    component: Aluminum,
  },
  "פלדה ומסגרות": {
    img: "/images/images19.jpg",
    icon: "/icons/icon26.svg",
  },
  "טיח וגמר קירות ": {
    img: "/images/images9.jpg",
    icon: "/icons/icon15.svg",
  },
  גבס: {
    tableClass: "table4",
    img: "/images/plaster.jpg",
    icon: "/icons/plaster.svg",
  },
  "אבן ושיש": {
    tableClass: "table4",
    icon: "/icons/icon19.svg",
    img: "/images/marble.jpg",
  },
  "דלתות חוץ ופנים": {
    img: "/images/doors.jpg",
    icon: "/icons/icon20.svg",
    component: Doors,
  },
  "מיזוג אוויר": {
    icon: "/icons/icon21.svg",
    group: 1,
    notFullPage: true,
  },
  גז: {
    icon: "/icons/icon22.svg",
    group: 1,
    notFullPage: true,
  },
  "חימום והסקה": {
    img: "/images/heating2.jpg",
    group: 1,
    icon: "/icons/icon23.svg",
    notFullPage: true,
  },
  תקשורת: {
    icon: "/icons/roshenroz_communication.svg",
    group: 5,
  },
  "סיכום לקוח": {
    icon: "/icons/icon29.svg",
    component: FinanceSummary,
    title: "סיכום עלויות",
    noSections: true,
  },
  "סיכום עלויות": {
    icon: "/icons/icon29.svg",
    component: FinanceSummary,
    title: "סיכום עלויות",
  },
  "טבלת זיכויים": {
    img: "/images/refunds.jpg",
    icon: "/icons/icon28.svg",
    tableClass: "table2",
    noDecimals: true,
    noSections: true,
    hideInCuanto: true,
  },
  "טבלת חשמל": {
    icon: "/icons/electric.svg",
    tableClass: "table2",
    altName: "מחירון חשמל",
    component: Electric,
    noSections: true,
    hideInCuanto: true,
  },
  "נגרות פנים ומטבח": {
    img: "/images/carpentry.jpg",
    icon: "/icons/roshenroz_carpentry.svg",
    tableClass: "table2",
  },
  איכלוס: {
    img: "/images/populate.jpg",
    icon: "/icons/populate.svg",
    notFullPage: true,
    group: 5,
  },
  שונות: {
    icon: "/icons/misc.svg",
    notFullPage: true,
    tableClass: "table2",
    group: 5,
  },
  "מידע והבהרות": {
    component: AttacheFinancial,
    icon: "/icons/attache.svg",
    img: "/images/attache.jpg",
  },
};

export const groupImages = {
  3: "/images/preparations.jpg",
  5: "/images/communication.jpg",
};
